<template>
  <div class="w-full">
    <div
      class="header-back-container w-full max-w-rifyo mx-auto"
      :class="rootClassName"
    >
      <div class="header-back-container1" @click="goBack()">
        <img
          :src="image_src1"
          :alt="image_alt1"
          class="header-back-image cursor-pointer"
        />
        <img
          :src="image_src"
          :alt="image_alt"
          class="header-back-image1 cursor-pointer"
        />
        <span class="header-back-text cursor-pointer">{{ text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeaderBack',
    props: {
      image_src1: {
        type: String,
        default: '/playground_assets/arrow-left-black.svg',
      },
      image_alt1: {
        type: String,
        default: 'image',
      },
      text: {
        type: String,
        default: 'Back To Profile',
      },
      rootClassName: String,
      image_src: {
        type: String,
        default: '/playground_assets/arrow_back_tosca.svg',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
      path: {
        type: String,
        default: null,
      },
    },
    methods: {
      goBack() {
        if (this.path === null) {
          this.$router.go(-1);
        } else {
          this.$router.push(this.path);
        }
      },
    },
  };
</script>

<style scoped>
  .header-back-container {
    @apply max-xl:px-5;

    width: 100%;
    height: 239px;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-top: 120px;
    flex-direction: column;
    background-color: #eae3d9;
  }

  .header-back-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-medium);
    display: flex;
    align-items: center;
  }
  .header-back-image {
    width: 24px;
    object-fit: cover;
  }
  .header-back-image1 {
    width: 24px;
    display: none;
    object-fit: cover;
  }
  .header-back-text {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: var(--dl-space-space-unit);
    letter-spacing: 4px;
    color: #1f252c;
  }

  @media (max-width: 991px) {
    .header-back-container {
      height: var(--dl-size-size-small);
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .header-back-container1 {
      height: 62px;
      padding-left: var(--dl-space-space-unit);
      background-size: cover;
      background-color: #fff;
      background-image: f20cba6f-186a-48cd-920e-080a1ade6f18;
    }
    .header-back-image {
      display: none;
    }
    .header-back-image1 {
      width: 24px;
      display: flex;
    }
    .header-back-text {
      font-size: 20px;
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      letter-spacing: 0;
    }
  }
</style>
