<template>
  <div>
    <div class="edit-info-container">
      <div class="edit-info-container1">
        <h1 class="edit-info-text">Forgot Password</h1>
        <input-box
          rootClassName="rootClassName1"
          text="New Password"
          textinputPlaceholder="min. 8 characters"
          type="password"
          v-model="password"
        ></input-box>
        <input-box
          rootClassName="rootClassName2"
          text="Confirmed New Password"
          textinputPlaceholder="min. 8 characters"
          type="password"
          v-model="password_confirmation"
        ></input-box>
        <div class="edit-info-btn-login" @click="createPassword()">
          <span class="edit-info-text1">Save New Password</span>
        </div>
      </div>
    </div>
    <div v-if="isError" @click="closeMsg()" class="alert-err">
      <XClose :text="msg_error" />
    </div>
    <div v-if="isSukses" @click="closeSukses()" class="alert-err">
      <XSukses :text="msg_sukses" />
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderBack from "../components/header-back.vue";
import InputBox from "../components/input-box.vue";
import Footer from "../components/footer";
import Api from "../api";
import XClose from "../components/x-close";
import XSukses from "../components/x-sukses";

export default {
  name: "ForgotPassword",
  props: {},
  data() {
    return {
      password: null,
      password_confirmation: null,
      isError: false,
      msg_error: null,
      isSukses: false,
      msg_sukses: null,
      profile: null,
    };
  },
  components: {
    HeaderBack,
    InputBox,
    Footer,
    XClose,
    XSukses,
  },
  created() {},
  methods: {
    closeMsg() {
      this.msg_error = null;
      this.isError = false;
    },
    openMsg(msg) {
      this.msg_error = msg;
      this.isError = true;
    },
    closeSukses() {
      this.msg_sukses = null;
      this.isSukses = false;
    },
    async createPassword() {
      try {
        if (!this.password || !this.password_confirmation) {
          this.isError = true;
          this.msg_error = "Password belum lengkap";
        } else {
          const rawResponse = await fetch(
            `${Api.createPasswordUrl}/${this.$route.query.data}`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                password: this.password,
                password_confirmation: this.password_confirmation,
              }),
            }
          );
          const content = await rawResponse.json();
          if (content.status == 200) {
            this.msg_sukses = "Changes saved";
            this.isSukses = true;
            this.password = null;
            this.password_confirmation = null;
            setTimeout(() => this.$router.push("/login"), 3000);
          } else {
            this.msg_error = content.message;
            this.isError = true;
          }
        }
      } catch (error){
        this.msg_error = error.message;
        this.isError = true;
      }
    },
  },
};
</script>

<style scoped>
.alert-err {
  position: fixed;
  right: 20px;
  bottom: 30px;
  width: 300px;
  z-index: 10000;
}
.edit-info-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #eae3d9;
  padding-bottom: 46px;
}
.edit-info-container1 {
  width: 492px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 239px;
}
.edit-info-text {
  color: #1f252c;
  font-family: Lora;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
}
.edit-info-btn-login {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 64px;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: flex-start;
  background-color: #1f252c;
}
.edit-info-text1 {
  color: #fff;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 2.4px;
}
@media (max-width: 991px) {
  .edit-info-container1 {
    width: 100%;
    margin-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-top: 100px;
  }
}
</style>
